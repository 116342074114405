<template>
  <div>
    <p class="text-2xl mb-6">TipoCuenta</p>

    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-text>
              <tipoCuenta-nuevo @CargarLista="CargarLista" :TipoCuentaDatos="tipoCuenta"></tipoCuenta-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">

          <tipoCuenta-lista ref="TipoCuentaListaRef" @GetEditarTipoCuenta="GetEditarTipoCuenta"></tipoCuenta-lista>
        </v-col>
      </v-row>

    </v-container>



  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TipoCuentaNuevo from './components/TipoCuentaNuevo.vue';
import { ref } from '@vue/composition-api';
import TipoCuentaLista from './components/TipoCuentaLista.vue';
// demos

export default {
  components: { TipoCuentaNuevo, TipoCuentaLista },
  setup() {
    const tipoCuenta = ref({
      id: -1,
      descripcion: "", 
    });
    const TipoCuentaListaRef = ref(null)
    const GetEditarTipoCuenta = (data) => {
    
      tipoCuenta.value = {
                    id: data.id, 
                    descripcion: data.descripcion, 
                }
    }

    const CargarLista = () => {
      TipoCuentaListaRef.value.cargarTipoCuentas()
    }
    return {
      tipoCuenta,
      CargarLista,
      GetEditarTipoCuenta,
      TipoCuentaListaRef
    }
  },
}
</script>
