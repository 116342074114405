<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :rules="reglas.descripcion"
              v-model="datos.descripcion"
              :label="'Descripcion'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarTipoCuenta()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
export default {
  components: { CropImagen },
  props: {
    TipoCuentaDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.TipoCuentaDatos)))
    const puestos = ref([])
    const form = ref()
    const cargando = ref(false)
    watch(props, () => {
      resetear()
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.TipoCuentaDatos))

      form.value.resetValidation()
    }
    onBeforeMount(() => {})

    const reglas = {
      id: -1,

      descripcion: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
       
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const limpiar = () => {
      datos.value = {
        id: -1,
        descripcion: '', 
      }
      form.value.resetValidation()
    }

    const GuardarTipoCuenta = async () => {
      try {
        if (validarForm()) {
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          CuentasService.nuevoTipoCuentaBanco(parametros)
            .then(response => {
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'GuardarTipoCuenta',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          
          error: error,
            funcion: 'GuardarTipoCuenta',
        })
        cargando.value = false
      }
    }

    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      GuardarTipoCuenta,
      form,
      limpiar,
      config,
      resetear,
      cargando,
    }
  },
}
</script>